var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "null-page"
  }, [_c('van-loading', {
    attrs: {
      "type": "spinner",
      "size": "36px",
      "color": "#1989fa",
      "vertical": ""
    }
  }, [_vm._v("加载中...")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };